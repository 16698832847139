import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroImage from "../components/shop/HeroImage"
import Categories from "../components/shop/Categories"

const Shop = props => {
  const {
    data: { shop, categories },
  } = props
  return (
    <Layout location={props.location.pathname}>
      <SEO
        title={``}
        description={``}
        metaImg={``}
        location={props.location.pathname}
      />
      <HeroImage data={shop} />
      <Categories categories={categories} />
    </Layout>
  )
}

export const ahopQuery = graphql`
  {
    shop: strapiShopPage {
      title
      hero_image {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      logo {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }

    categories: allStrapiCategory {
      edges {
        node {
          id
          name
          slug
          description
          image {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          products {
            id
          }
        }
      }
    }
  }
`

export default Shop
