import React from "react"
import BgImg from "gatsby-background-image"
import Img from "gatsby-image"
import styled from "styled-components"
import { colors, headlineTwo } from "../../Utilities"

const HeroImageStyled = styled.div`
  position: relative;
  width: 100%;
  .hero-image {
    width: 100%;
    height: 40rem;
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;

    @media (min-width: 768px) {
      height: ${props => (props.adventure ? "40rem" : "60rem")};
    }

    @media (min-width: 1025px) {
      height: ${props => (props.adventure ? "50rem" : "75rem")};
    }
  }

  .hero-logo-container {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 25rem;
    transform: translate(-50%, -50%);
    z-index: 99999;

    @media (min-width: 768px) {
      width: 30rem;
    }

    @media (min-width: 1025px) {
      width: 40rem;
    }

    .title {
      width: 100%;
      text-align: center;

      h2 {
        ${headlineTwo};
        color: ${colors.white};
        text-transform: uppercase;
      }
    }

    img {
      width: 100%;
    }
  }

  .hero-image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000000;
    opacity: 0.39;
    z-index: 9999;
  }
`

const HeroImage = ({ data }) => {
  const mainBgImage = data.hero_image.childImageSharp.fluid
  const mainLogo = data.logo.childImageSharp.fluid
  const title = data.title
  const adventure = false
  return (
    <HeroImageStyled adventure={adventure}>
      <BgImg className="hero-image" Tag="div" fluid={mainBgImage} />
      <div className="hero-logo-container">
        <div>
          <Img fluid={mainLogo} />
        </div>
        <div className="title">
          <h2>{title}</h2>
        </div>
      </div>

      <div className="hero-image-overlay" />
    </HeroImageStyled>
  )
}

export default HeroImage
