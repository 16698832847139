import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import BGImage from "gatsby-background-image"

import {
  bodyCopy,
  buttonOne,
  colors,
  headlineThree,
  standardWrapper,
} from "../../Utilities"

const Categories = ({ categories }) => {
  const allCategories = categories.edges
  const allCats = allCategories.filter(({ node }) => node.slug !== "adventures")
  const upperSmall = allCats.slice(1, 3).reverse()
  const theRest = allCats.slice(3)

  return (
    <CategoriesStyled>
      <div className="wrapper">
        <div className="upperCats">
          <div className="upperCats__bigCard">
            <UpperCards to={`/shop/${allCats[0].node.slug}`}>
              <div className="innerContent">
                <h2>{allCats[0].node.name}</h2>
                <p>{allCats[0].node.description}</p>
                <button type="button">Shop</button>
              </div>
              <div className="bgborder" />
              <div className="bgOverlay" />
              <div className="bgImage">
                <BGImage
                  tag="div"
                  fluid={allCats[0].node.image.childImageSharp.fluid}
                />
              </div>
            </UpperCards>
          </div>
          <div className="upperCats__smallCards">
            {upperSmall.map((cat, index) => {
              return (
                <UpperCards
                  to={`/shop/${cat.node.slug}`}
                  key={cat.node.id}
                  catindex={`${index + 1}`}
                >
                  <div className="innerContent">
                    <h2>{cat.node.name}</h2>
                    <p>{cat.node.description}</p>
                    <button type="button">Shop</button>
                  </div>
                  <div className="bgborder" />
                  <div className="bgOverlay" />
                  <div className="bgImage">
                    <BGImage
                      tag="div"
                      fluid={cat.node.image.childImageSharp.fluid}
                    />
                  </div>
                </UpperCards>
              )
            })}
          </div>
        </div>

        {theRest.map((cat, index) => {
          return (
            <CatCard
              to={`/shop/${cat.node.slug}`}
              key={cat.node.id}
              catindex={`${index + 1}`}
            >
              <div className="innerContent">
                <h2>{cat.node.name}</h2>
                <p>{cat.node.description}</p>
                <button type="button">Shop</button>
              </div>
              <div className="bgborder" />
              <div className="bgOverlay" />
              <div className="bgImage">
                <BGImage
                  tag="div"
                  fluid={cat.node.image.childImageSharp.fluid}
                />
              </div>
            </CatCard>
          )
        })}
      </div>
    </CategoriesStyled>
  )
}

const CategoriesStyled = styled.div`
  position: relative;
  margin-top: -12rem;
  z-index: 10000;

  .wrapper {
    ${standardWrapper};
    justify-content: flex-start;
  }

  .upperCats {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;

    &__bigCard {
      display: flex;
      position: relative;
      width: 100%;

      @media (min-width: 768px) {
        width: calc(50% - 2rem);
        margin: 1rem;
      }
    }

    &__smallCards {
      display: flex;
      flex-wrap: wrap;
      position: relative;
      width: 100%;

      @media (min-width: 768px) {
        width: calc(50% - 2rem);
        margin: 1rem;
      }
    }
  }
`

const UpperCards = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  margin: 2rem auto;

  .innerContent {
    position: relative;
    width: 100%;
    padding: 5rem;
    text-align: center;
    z-index: 10;

    h2 {
      ${headlineThree};
      margin-top: 0;
      margin-bottom: 0.5rem;
      color: ${colors.white};
      line-height: 1.5;
      text-transform: uppercase;
    }

    p {
      ${bodyCopy};
      margin-top: 0;
      margin-bottom: 3.5rem;
      color: ${colors.white};
    }

    a,
    button {
      ${buttonOne};
    }
  }

  .bgborder {
    position: absolute;
    top: 1rem;
    right: 1rem;
    bottom: 1rem;
    left: 1rem;
    border: solid 0.25rem ${colors.white};
    z-index: 3;
  }

  .bgOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${colors.black};
    opacity: 0.5;
    z-index: 2;
  }

  .bgImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 1 !important;

    div {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 1 !important;
    }
  }
`
const CatCard = styled(Link)`
  position: relative;
  width: 100%;
  margin: 2rem auto;

  @media (min-width: 768px) {
    width: calc(50% - 2rem);
    margin: 1rem;
  }

  .innerContent {
    position: relative;
    width: 100%;
    padding: 5rem;
    text-align: center;
    z-index: 10;

    h2 {
      ${headlineThree};
      margin-top: 0;
      margin-bottom: 0.5rem;
      color: ${colors.white};
      line-height: 1.5;
      text-transform: uppercase;
    }

    p {
      ${bodyCopy};
      margin-top: 0;
      margin-bottom: 3.5rem;
      color: ${colors.white};
    }

    a,
    button {
      ${buttonOne};
    }
  }

  .bgborder {
    position: absolute;
    top: 1rem;
    right: 1rem;
    bottom: 1rem;
    left: 1rem;
    border: solid 0.25rem ${colors.white};
    z-index: 3;
  }

  .bgOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    ${"" /* background-color: ${colors.colorTertiary}; */}
    opacity: 0.7;
    z-index: 2;
  }

  .bgImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 1 !important;

    div {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 1 !important;
    }
  }
`

export default Categories
